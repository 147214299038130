import { Input, notification, Spin } from 'antd'
import { InputOTP } from 'antd-input-otp'
import React, { useState } from 'react'
import { UAParser } from 'ua-parser-js'
import Settings from '../../shared/config/Settings'
import getUserId from '../../shared/core/GetToken'


const MfaLoginUI = ({ timer, setTimer, setIsMFAVerified, setIsMFAActive }) => {
    const [otpPass, setOtpPass] = useState("")
    const [authenticatorQrData, setAuthenticatorQrData] = useState("")
    const [authPinCode, setAuthPinCode] = useState("")
    const [authenticatorActive, setAuthenticatorActive] = useState(false)
    const [validatingOTP, setValidatingOTP] = useState(false)
    const [isResendClickedOnce, setIsResendClickedOnce] = useState(false)
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
        });
    };
    const verifyOTP = () => {
        let otp = ""
        otpPass.length > 0 && otpPass?.map(o => otp = otp + o)
        if (otp.length < 6 || !parseInt(otp)) {
            openNotificationWithIcon("error", "Please enter a valid OTP")
            return
        }
        setValidatingOTP(true)
        const parser = new UAParser();
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
                Otpcode: parseInt(otp),
                Version: parser.getResult()?.browser?.version,
                Platform: "Web",
                DeviceVersion: parser.getResult()?.browser?.name,
                "IsFromWeb": true
            })
            debugger
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "Patient/ValidateOtp?", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setValidatingOTP(false)
                    if (result === 1) {
                        setIsMFAVerified(true)
                    }
                    else if (result === -2) {
                        setIsMFAVerified(false)
                        openNotificationWithIcon("error", "OTP has expired")
                    }
                    else if (result === -1) {
                        setIsMFAVerified(false)
                        openNotificationWithIcon("error", "Provided OTP is invalid")
                    }
                    else {

                        openNotificationWithIcon("error", "Some error occured, Please try again")
                        setIsMFAVerified(false)
                    }
                })
                .catch(err => {
                    setValidatingOTP(false)

                })
        })
    }
    const authenticatePin = () => {
        let otp = ""
        authPinCode.length > 0 && authPinCode?.map(o => otp = otp + o)
        if (otp.length < 6 || !parseInt(otp)) {
            openNotificationWithIcon("error", "Please enter a valid pin code")
            return
        }
        setValidatingOTP(true)
        const parser = new UAParser();
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientUUID: item.userid,
                Code: otp,
                UserName: item.parentname,

            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "Patient/VerifyGoogleAuthOtp?", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    setValidatingOTP(false)
                    if (result === "Invalid Code") {
                        openNotificationWithIcon("error", "Invalid pin code, please make sure the pin code is correct")
                        setIsMFAVerified(false)
                    }
                    else if (result === "1") {
                        setIsMFAVerified(true)

                    } else {
                        openNotificationWithIcon("error", "Invalid pin code, please make sure the pin code is correct")
                        setIsMFAVerified(false)
                    }
                })
                .catch(err => {
                    setValidatingOTP(false)

                })
        })
    }
    const resendOTPCall = () => {
        if (timer > 0) {
            return
        }
        setIsResendClickedOnce(true)
        setOtpPass("")
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientID: item.userid,
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "Patient/ResendOtp", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result === "1") {
                        openNotificationWithIcon("success", "A new OTP has been sent to you. Please verify to login.")
                        setTimer(30)
                    }

                })
                .catch(err => {
                    setValidatingOTP(false)

                })
        })
    }
    const generateOtpQRSecret = () => {
        setIsResendClickedOnce(true)
        setAuthenticatorActive(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                PatientUUID: item.userid,
                UserName: item.username
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "Patient/GenerateQRAuthenticator", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result?.QrCodeUrl) {
                        setAuthenticatorQrData(result.QrCodeUrl)
                    }
                    // if (result === "1") {
                    // openNotificationWithIcon("success", "A new OTP has been sent to you. Please verify to login.")
                    // setTimer(30)
                    // }

                })
                .catch(err => {
                    setValidatingOTP(false)

                })
        })
    }
    return (
        <div className='mfa-box'>
            <img src="assets/images/back-arrow.svg" onClick={() => {
                setIsMFAActive(false)
                setIsMFAVerified(false)
                localStorage.clear()
            }} className="mfa-login-back-button" alt="Back" />

            <div className="mfa-box-title mb-2"><span>{authenticatorActive ? "Google Authenticator" : "Multi-Factor Authentication"}</span></div>
            <div className='mfa-box-text mb-4'>
                {authenticatorActive ?
                    <span>Scan the QR code on your authenticator app and enter the pin code below to proceed</span>
                    :
                    <span>A message with a verification code has been sent to your Email</span>}
            </div>
            {!authenticatorActive && <div className='mfa-box-time-count mb-4'><span className='time-span'>00 : {timer < 10 ? `0${timer}` : timer}</span></div>}
            {!authenticatorActive && <div className='mfa-box-input mb-4'> <InputOTP inputClassName="otp-input" value={otpPass} onChange={(e) => setOtpPass(e)} /></div>}
            {authenticatorActive ?
                <div className='authenticator-qr-area'>
                    {authenticatorQrData ? <img className='w-100' src={authenticatorQrData} /> : <Spin />}
                    <div className='mfa-box-input mt-4 mb-4'> <InputOTP inputClassName="otp-input" value={authPinCode} onChange={(e) => setAuthPinCode(e)} />
                    </div>
                </div>
                : null}

            {validatingOTP ? <Spin /> : null}
            {!isResendClickedOnce && <div className={'mfa-box-resend-code-link mb-4' + (timer <= 0 ? " cursor-pointer " : " cursor-not-allowed disabled ")} onClick={resendOTPCall}>Resend Code</div>}
            {isResendClickedOnce && !authenticatorActive && <div className={'mfa-box-resend-code-link mb-4' + (timer <= 0 ? " cursor-pointer " : " cursor-not-allowed disabled ")} onClick={generateOtpQRSecret}>Try another way</div>}
            <div className='mfa-box-submit'><button disabled={validatingOTP} onClick={() => authenticatorActive ? authenticatePin() : verifyOTP()} className='btn btn-primary btn-lg'>Continue</button></div>
            {/* {!authenticatorActive && <div className={'mfa-box-resend-code-link mb-4 cursor-pointer '} onClick={generateOtpQRSecret}>Try another way</div>} */}
        </div>
    )
}

export default MfaLoginUI
